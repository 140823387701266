.header {
  width: 100vw;
  height: 68px;
  background-color: #3890fc;
  /* position: absolute; */
}
.menu {
  /* Pendingn media query */
  margin-left: auto;
  margin-right: auto;
  width: 71%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Raleway", sans-serif;
}

.logo {
  /* margin-top: 20px; */
  font-size: 2rem;
  /* margin-bottom: 1em; */
  font-weight: bold;
  cursor: pointer;
}
.fill {
  flex: 1;
}
.navLinksRoot {
  margin-block-start: 0px;
  margin-block-end: 0px;

  display: flex;
}

.navLink {
  list-style-type: none;
  display: block;
  margin-left: 50px;
  /* padding: 5px; */
  font-size: 1rem;
}
.navLink a {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}
.supplementalInfo {
  margin-left: auto;
  margin-right: auto;
  width: 71%;
  display: flex;
  position: relative;
  top: 10%;
  /* padding-top: 80px; */
}

.announcement {
  background-color: white;
  font-family: "Raleway", sans-serif;
  font-size: 25px;
  font-weight: bold;
}

.announcementText {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 500px;
  padding: 20px;
  text-align: left;
}

.menuIcon {
  top: 50px;
}

.menuIcon svg {
  color: white;
}

@media (max-width: 600px) {
  .navLinksRoot {
    display: none;
  }
  .logo {
    flex: 1;
    text-align: center;
  }
  .fill {
    flex: 0;
  }
}
